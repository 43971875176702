<template>
  <div>
    <!-- <v-row justify="space-around">
      <v-col>
        <v-chip-group column active-class="primary--text" v-model="tab">
          <v-chip v-for="tag in tags" :key="tag" :value="tag">{{ tag }}</v-chip>
        </v-chip-group>
      </v-col>
    </v-row> -->
    <keep-alive>
      <component v-bind:is="currentComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import Payments from "@/components/Admin/Payment/Payments";

export default {
  components: {
    Payments,
  },
  data() {
    return {
      tab: null,
      tags: [
        this.$helpers.string.capitalizeI18N("payments"),
        // $helpers.string.capitalizeI18N("clients"),
        // $helpers.string.capitalizeI18N("memberships"),
      ],
    };
  },
  computed: {
    currentComponent: function () {
      switch (this.tab) {
        case this.$helpers.string.capitalizeI18N("payments"):
          return "Payments";
        default:
          return "Payments";
      }
    },
  },
  watch: {
    tab: function (val) {
      const path = this.$route.path + "?tab=" + encodeURIComponent(val);

      if (this.$route.fullPath !== path) this.$router.push(path);
    },
  },
  methods: {},
  mounted() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  },
};
</script>